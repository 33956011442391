import { SearchDomain } from '../search-types';

const searchDomainDisplayStrings: Record<SearchDomain, string> = {
  apiReference: 'API Reference',
  docs: 'Documentation',
  webSdkReference: 'Web SDK Reference',
  apiExplorer: 'API Explorer',
  devDashboard: 'Developer Dashboard',
  forum: 'Forum',
  blog: 'Blog',
};

const fuseSearchCategories = {
  apiReference: {
    reference: 'Reference',
    endpoint: 'Endpoint',
    webhook: 'Webhook',
    object: 'Object',
    enum: 'Enum',
    error: 'Error',
    deprecatedEndpoint: 'Deprecated endpoint',
    deprecatedWebhook: 'Deprecated webhook',
    property: 'Property',
  },
  docs: {
    guide: 'Guide',
    changelog: 'Changelog',
  },
};

const globalSearchCategories = {
  apiReference: {
    reference: 'Reference',
    endpoint: 'Endpoint',
    webhook: 'Webhook',
    object: 'Object',
    enum: 'Enum',
    error: 'Error',
    deprecatedEndpoint: 'Deprecated endpoint',
    property: 'Property',
  },
  docs: {
    guide: 'Guide',
    changelog: 'Changelog',
  },
  webSdkReference: {
    reference: 'Reference',
    object: 'Object',
    enum: 'Enum',
    error: 'Error',
    method: 'Method',
    property: 'Property',
  },
  apiExplorer: {
    explorer: 'Explorer',
  },
  devDashboard: {
    dashboard: 'Dashboard',
  },
  forum: {
    discussion: 'Discussion',
  },
  blog: {
    blogPost: 'Blog post',
  },
};

export {
  searchDomainDisplayStrings,
  fuseSearchCategories,
  globalSearchCategories,
};
